import React, { useRef, useState } from "react";
import styles from './styles.module.styl';

const Nomination = ({ nomination }) => {
    const [showInformation, setShowInformation] = useState(false);
    const infoIconRef = useRef(null);

    const handleInformationClick = () => {
        if (showInformation) {
            setShowInformation(false);
            infoIconRef.current.classList.remove(styles.infoIconSelected);
        } else {
            setShowInformation(true);
            infoIconRef.current.classList.add(styles.infoIconSelected);
        }
    };

    return (
        <div key={nomination.title} className={styles.nomination}>
            <span className={styles.nominationIndex}>{nomination.id}</span>
            <div className={styles.headerAndLogo}>
                <div className={styles.nominationHead}>
                    <h4 className={styles.nominationTitle}>{nomination.title}</h4>
                    <button
                        ref={infoIconRef}
                        onClick={handleInformationClick}
                        className={styles.infoIcon}
                    >
                        <div className={styles.iContainer}>i</div>
                    </button>
                </div>
                {nomination.winnerLogo && <img src={nomination.winnerLogo} alt="winner" className={styles.winner} />}
                {nomination.title === 'Бизнес-лидер года' && (
                    <div className={`${styles.winner} ${styles.winnerText}`}>
                        <div className={styles.manOfYear}>
                            Юлия Гадлиба
                        </div>
                        <div>
                            ПАО «Группа Ренессанс Страхование»
                        </div>
                    </div>
                )}
                {nomination.title === 'Человек года' && (
                    <div className={`${styles.winner} ${styles.winnerText}`}>
                        NoGo1
                    </div>
                )}
            </div>
            {showInformation &&
                <p className={styles.nominationDescription}>
                    {nomination.description}
                    {nomination.descriptionItems &&
                        <ul className={styles.descriptionItems}>
                            {nomination.descriptionItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        {item}
                                    </li>
                                );
                            })}
                        </ul>
                    }
                </p>
            }
        </div>
    );
};

export default Nomination;
