export const DESKTOP = 'desktop'
export const TABLET = 'tablet'
export const MOBILE = 'mobile'

export function getDeviceType() {
    if (window.matchMedia("(min-width: 1200px)").matches) {
        return DESKTOP;
    } else if (window.matchMedia("(min-width: 568px)").matches) {
        return TABLET;
    } else {
        return MOBILE;
    }
}
