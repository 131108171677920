import React from "react";
import styles from './styles.module.styl';

const LayoutWrapper = ({ children, className='' }) => {
    return (
        <div className={`${styles.layoutWrappper} ${className}`}>
            { children }
        </div>
    );
};

export default LayoutWrapper;
