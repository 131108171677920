import React from "react";
import styles from './styles.module.styl';

import PageSection from '../../components/page-section';
import Line from '../../components/line';
import SectionName from "../../components/section-name";

const rewards = [
    'За востребованные и выгодные финансовые продукты',
    'За высокий уровень клиентского сервиса',
    'За вклад в развитие отрасли'
];

const AboutPremium = () => {
    return (
        <PageSection className={styles.aboutPremium}>
            <SectionName
                name='О премии'
                className={styles.sectionName}
            />
            <h3 className={styles.title}>
            За 15 лет мы вручили более 300 наград лучшим <span className={styles.titlePurple}>банкам, МФО, страховым и инвестиционным компаниям</span>
            </h3>
            <ul className={styles.rewards}>
                {rewards.map(reward => {
                    return (
                        <li key={reward}>
                            <Line className={styles.rewardLine}/>
                            <p className={styles.rewardText}>{reward}</p>
                        </li>
                    );
                })}
            </ul>
        </PageSection>
    );
};

export default AboutPremium;
