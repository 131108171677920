import React, { useState, useEffect } from "react";
import PageSection from "../../components/page-section";
import styles from './styles.module.styl';
import { BANKIRU_URL } from "../../../constants";

const BlitzInterview = () => {
    const [speakers, setSpeakers] = useState(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const res = await fetch(`${BANKIRU_URL}/news/api/bank_of_year/internet`);
                if (!res.ok || res.status !== 200) {
                    console.log('fetch news error');
                    return;
                }
                const resJson = await res.json();
                if (resJson.error) {
                    console.log('fetch news error');
                    return;
                }
                setSpeakers(resJson.items);
            } catch(e) {
                console.log('fetch news error');
            }
        }

        fetchNews();
    }, []);

    const isImageExist = (images) => {
        if (
            images
            && images.facebook_image
            && images.facebook_image.original
            && images.facebook_image.original.file
        ) {
            return true;
        }
        return false;
    };

    const refactorDate = (fullDate) => {
        const date = fullDate.split(' ')[0];
        return date.split('-').reverse().join('.');
    };

    return (
        <PageSection className={styles.pageSection}>
            <h1 className={styles.title}>
                Блиц-интервью<br/>
                <span className={styles.purple}>с лауреатами премии</span>
            </h1>
            {speakers ? (
                 <div className={styles.speakers}>
                    {speakers.map(({ id, images, title, date }, index) => (
                        <a
                            href={`${BANKIRU_URL}/news/daytheme/?id=${id}`}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.speaker}
                            key={index}
                        >
                            {isImageExist(images) ? (
                                <img
                                    src={images.facebook_image.original.file}
                                    alt="speaker"
                                    className={styles.speakerImage}
                                />
                            ) : (
                                <div className={styles.imagePlaceholder}/>
                            )}
                            <p className={styles.speakerTitle}>{title}</p>
                            <p className={styles.date}>{refactorDate(date)}</p>
                        </a>
                    ))}
                </div>
            ) : (
                <div className={styles.blockPlaceholder}/>
            )}
        </PageSection>
    );
};

export default BlitzInterview;
