import React from "react";
import styles from './styles.module.styl';

import logo from '../../static/images/header/logo-desc.svg';

const Header = () => {
    return (
        <header className={`${styles.layoutWrapper} ${styles.header}`}>
            {/* todo: добавить переход по ссылке */}
            <a
                target='_blank'
                rel="noreferrer"
                href="https://www.banki.ru/"
            > 
                <img
                    src={logo}
                    alt="logo"
                    className={styles.logo}
                />
            </a>
        </header>
    );
};

export default Header;
