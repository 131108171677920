import sovcombank from '../../../../assets/images/sovcombank.svg';
import tinkoff from '../../../../assets/images/tinkoff.svg';

const customerСhoice = [
    {
        id: '05',
        title: 'Победитель «Народного рейтинга» банков',
        description: 'Победителем становится лидер «Народного рейтинга» банков по итогам 2021 года',
        winnerLogo: sovcombank,
    },
    {
        id: '06',
        title: 'Победитель «Народного рейтинга» страховых компаний',
        description: 'Победителем становится лидер «Народного рейтинга» страховых компаний по итогам 2021 года',
        winnerLogo: tinkoff,
    },
    {
        id: '07',
        title: '«Забота о клиенте»',
        description: 'В этой номинации экспертным советом премии комплексно оценивается работа банка в сервисах Банки.ру: активность на «Форуме» и в «Народном рейтинге», работа представителя банка в сервисе «Вопрос — ответ», комментарии и отзывы пользователей. Учитываются как количественные показатели (скорость ответа, полнота предоставляемой информации), так и индивидуальный подход и клиентоориентированность при рассмотрении вопросов и жалоб пользователей сайта',
        winnerLogo: tinkoff,
    }
];

export default customerСhoice;
