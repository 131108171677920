import React from "react";
import styles from './style.module.styl';

const ObliqueSpacer = () => {
    return (
        <div className={styles.obliqueSpacer}/>
    );
};

export default ObliqueSpacer;
