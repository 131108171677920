function initAnimation() {
    const wrapper = document.getElementById('splash-screen-wrapper');

    // только для десктопа
    if (!window.matchMedia("(min-width: 1200px)").matches) {
        return;
    }

    const wrapperHeight = wrapper.offsetHeight;
    const animationStart = wrapperHeight * 0.2;
    const animationFinish = wrapperHeight * 0.6;
    const animationHeight = animationFinish - animationStart;

    const BOTTOM = 'bottom';
    const TOP = 'top';
    let prevViewportTop = 0;
    let ticking = false;

    let progress = 0;
    const minProgress = 0;
    const maxProgress = 1;
    let opacity = 1;

    const getProgress = (viewportTop) => {
        const alreadyPassed = viewportTop - animationStart;
        const progress = alreadyPassed / animationHeight;
        return progress;
    }

    const handleScroll = () => {
        const viewportTop = window.scrollY;

        const direction = viewportTop > prevViewportTop ? BOTTOM : TOP;

        // скроллим вниз
        if (direction === BOTTOM && progress < maxProgress && viewportTop > animationStart) {
            if (!ticking) {
                window.requestAnimationFrame(function() {
                    progress = getProgress(viewportTop);
                    if (progress > 1) {
                        progress = 1;
                    }
                    wrapper.style = `opacity: ${opacity - opacity * progress}`;
                    ticking = false;
                });
                ticking = true;
            }
        }

        // скроллим вверх
        if (direction === TOP && progress > minProgress && viewportTop < animationFinish) {
            if (!ticking) {
                window.requestAnimationFrame(function() {
                    progress = getProgress(viewportTop);
                    if (progress < 0) {
                        progress = 0;
                    }
                    wrapper.style = `opacity: ${opacity - opacity * progress}`;
                    ticking = false;
                });
                ticking = true;
            }
        }

        prevViewportTop = viewportTop;
    };

    window.addEventListener('scroll', handleScroll);
};

export default initAnimation;
