import raiffeisen from '../../../../assets/images/raiffeisen.svg'
import postBank from '../../../../assets/images/post-bank.svg'
import domRF from '../../../../assets/images/dom-rf.svg'
import uralBank from '../../../../assets/images/ural-bank.svg'
import tinkoff from '../../../../assets/images/tinkoff.svg'
import finam from '../../../../assets/images/finam.svg'
import homeCreditBank from '../../../../assets/images/home-credit-bank.svg'
import alfaBank from '../../../../assets/images/alfa-bank.svg'
import gasprombank from '../../../../assets/images/gasprombank.svg'
import rosbank from '../../../../assets/images/rosbank.svg'
import alfaInsurence from '../../../../assets/images/alfa-insurence.svg'

const bestProducts = [
    {
        id: '08',
        title: 'Потребительский кредит года (оценка экспертного совета премии)',
        description: 'При выборе победителя мы оцениваем процентную ставку по кредиту и ее динамику в течение года, наличие дополнительных условий по снижению или увеличению ставки, срок финансирования, требования к пакету документов, скорость и удобство оформления продукта, его доступность различным категориям граждан и др.',
        winnerLogo: raiffeisen,
    },
    {
        id: '09',
        title: 'Потребительский кредит года. Выбор пользователей Банки.ру',
        description: 'Определяется по количеству заявок, поданных через сервисы Банки.ру',
        winnerLogo: postBank,
    },
    {
        id: '10',
        title: 'Вклад года (оценка экспертного совета премии)',
        description: 'При выборе победителя мы оцениваем процентную ставку по вкладу на протяжении года, срок размещения средств и минимальную сумму депозита, учитываем дополнительные опции — пополнение, частичное снятие, льготное досрочное расторжение договора, выплату процентов, возможность онлайн-оформления и др. Обращаем внимание на доступность продукта в разных регионах, а также на отзывы клиентов о вкладе и кредитной организации',
        winnerLogo: domRF,
    },
    {
        id: '11',
        title: 'Вклад года. Выбор пользователей Банки.ру',
        description: 'Определяется по количеству заявок, поданных через сервисы Банки.ру',
        winnerLogo: uralBank,
    },{
        id: '12',
        title: 'Инвестиционный продукт года (оценка экспертного совета премии)',
        description: 'При выборе победителя мы оцениваем уровень инновационности и привлекательности продукта для клиентов, самостоятельность сервиса, уровень клиентского обслуживания, условия получения продукта (минимальные суммы, комиссия, лимиты), доходность продукта (фактическая или расчетная). Кроме того, мы принимаем во внимание отзывы клиентов — как о продукте, так и о самой инвестиционной компании',
        winnerLogo: tinkoff,
    },
    {
        id: '13',
        title: 'Инвестиционный продукт года. Выбор пользователей Банки.ру',
        description: 'Определяется по количеству заявок, поданных через сервисы Банки.ру',
        winnerLogo: finam,
    },
    {
        id: '14',
        title: 'Кредитная карта года (оценка экспертного совета премии)',
        description: 'При выборе победителя мы оцениваем продолжительность и условия льготного периода кредитования, возможность бесплатного выпуска и обслуживания карты, наличие и содержание бонусной программы, прозрачность ее условий. Обращаем внимание на клиентский опыт и отзывы держателей',
        winnerLogo: homeCreditBank,
    },
    {
        id: '15',
        title: 'Кредитная карта года. Выбор пользователей Банки.ру',
        description: 'Определяется по количеству заявок, поданных через сервисы Банки.ру',
        winnerLogo: alfaBank,
    },
    {
        id: '16',
        title: 'Дебетовая карта года (оценка экспертного совета премии)',
        description: 'При выборе победителя мы оцениваем условия получения процентного дохода по карте, возможность бесплатного обслуживания, комиссии и лимиты на снятие наличных и переводы, содержание бонусной программы и прозрачность ее условий, доступность онлайн-оформления и доставки карты, отзывы клиентов о банке и продукте',
        winnerLogo: gasprombank,
    },
    {
        id: '17',
        title: 'Дебетовая карта года. Выбор пользователей Банки.ру',
        description: 'Определяется по количеству заявок, поданных через сервисы Банки.ру',
        winnerLogo: homeCreditBank,
    },
    {
        id: '18',
        title: 'Ипотечный кредит года  (оценка экспертного совета премии)',
        description: 'При выборе победителя мы оцениваем размер процентных ставок, динамику их изменения в течение года, минимальный первоначальный взнос, требования к страхованию, наличие дополнительных опций (как снижающих, так и увеличивающих ставку), уровень цифровизации и удобство процесса оформления ипотеки для заемщика',
        winnerLogo: domRF,
    },
    {
        id: '19',
        title: 'Ипотечный кредит года. Выбор пользователей Банки.ру',
        description: 'Определяется по количеству заявок, поданных через сервисы Банки.ру',
        winnerLogo: rosbank,
    },
    {
        id: '20',
        title: 'Страховой продукт года (оценка экспертного совета премии)',
        description: 'При выборе победителя мы оцениваем страховое покрытие и возможность динамического управления параметрами, наличие аналогов, наличие сервисной составляющей, диджитальность (онлайн-покупка/онлайн-урегулирование), присутствие в каналах дистрибуции',
        winnerLogo: alfaInsurence,
    },
];

export default bestProducts;
