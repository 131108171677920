import React from "react";
import styles from './styles.module.styl';

import Line from "../line";

const SectionName = ({ name, className='' }) => {
    return (
        <div className={`${styles.sectionName} ${className}`}>
            <Line className={styles.line}/>
            <p className={styles.name}>{name}</p>
        </div>
    );
};

export default SectionName;
