import React, { useEffect } from "react";
import styles from './styles.module.styl';

import PageSection from "../../components/page-section";

import diamondDesc from '../../static/images/premium-animation/diamond-desc.png';
import diamondDescWebp from '../../static/images/premium-animation/diamond-desc.webp';
import polygonDesc from '../../static/images/premium-animation/polygon-desc.png';
import polygonDescWebp from '../../static/images/premium-animation/polygon-desc.webp';
import smallDiamond from '../../static/images/premium-animation/small-diamond-mob.png';
import smallDiamondWebp from '../../static/images/premium-animation/small-diamond-mob.webp';

import diamondDescX2 from '../../static/images/premium-animation/diamond-desc-x2.png';
import diamondDescWebpX2 from '../../static/images/premium-animation/diamond-desc-x2.webp';
import polygonDescX2 from '../../static/images/premium-animation/polygon-desc-x2.png';
import polygonDescWebpX2 from '../../static/images/premium-animation/polygon-desc-x2.webp';

import initAnimation from './animation';


const texts = [
    {
        title: 'Премия, которая помогает стать лучше',
        description: 'Клиент получает достоверную и непредвзятую оценку финансового продукта и компании, а финансовый бизнес — ориентиры для дальнейшего развития'
    },
    {
        title: 'Премия, которой доверяют',
        description: 'В выборе победителей участвует не только экспертный совет премии, но и сами клиенты — с помощью онлайн-голосования и «Народного рейтинга»'
    }
];

const PremiumAnimation = () => {
    useEffect(() => {
        initAnimation();
    }, []);

    const createTextContent = (text) => {
        return  (
            <>
                <h4 className={styles.textTitle}>{text.title}</h4>
                <p className={styles.textDescription}>{text.description}</p>
            </>
        );
    };

    return (
        <div
            id='premium-animation-animation-container'
            className={styles.sectionContainer}
        >
            <PageSection className={styles.premiumAnimation}>
                <div 
                    id='premium-animation-text1'
                    className={`${styles.textPart1} ${styles.textPart1Appear}`}
                >
                    {createTextContent(texts[0])}
                </div>

                <div className={styles.animationContainer}>
                    <picture>
                        <source type='image/webp' srcSet={`${polygonDescWebp} 1x, ${polygonDescWebpX2} 2x`} />
                        <img
                            src={polygonDesc}
                            srcSet={`${polygonDescX2} 2x`}
                            alt="polygon-desc"
                            className={styles.polygon}
                        />
                    </picture>
                    <picture>
                        <source type='image/webp' srcSet={`${diamondDescWebp} 1x, ${diamondDescWebpX2} 2x`} />
                        <img
                            id='premium-animation-diamond'
                            src={diamondDesc}
                            srcSet={`${diamondDescX2} 2x`}
                            alt="diamond-desc"
                            className={styles.diamond}
                        />
                    </picture>
                    <picture>
                        <source type='image/webp' srcSet={smallDiamondWebp} />
                        <img
                            src={smallDiamond}
                            alt="small-diamond-mob"
                            className={styles.smallDiamond}
                        />
                    </picture>
                </div>

                <div
                    id='premium-animation-text2'
                    className={`${styles.textPart2} ${styles.textPart2Disappear}`}
                >
                    {createTextContent(texts[1])}
                </div>
            </PageSection>
        </div>
    );
};

export default PremiumAnimation;
