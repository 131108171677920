import React, { useEffect, useRef } from 'react';
import styles from './styles.module.styl';

import PageSection from '../../components/page-section';
import SectionName from '../../components/section-name';
import Nomination from '../nominations/nomination';
import tinkoff from '../../../assets/images/tinkoff.svg'

const nomination = {
    id: '',
    title: 'Digital-банк года',
    description: 'Выбор победителя в данной номинации состоит из нескольких этапов:',
    descriptionItems: [
        'Экспертный совет премии определяет топ-10 номинантов. При выборе номинантов мы учитываем количество каналов коммуникации с банком, уровень автоматизации общения клиента с банком, удобство мобильного приложения, доступные клиентам платежные технологии и сервисы, возможность онлайн-оформления и доставки продуктов банка и др.',
        'Из представленного на сайте списка пользователям необходимо выбрать банк, достойный победы в номинации «Digital-банк года» и соответствующий всем критериям номинации (удобство мобильного приложения, уровень автоматизации общения банка с клиентом, возможность онлайн-оформления и доставки продуктов банка). Голосование будет открыто 24 января 2022 года'
    ],
    winnerLogo: tinkoff,
};

const Voting = () => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const params = new URLSearchParams(document.location.search);
        const scrollToVoting = params.get('scrollToVoting');
        if (scrollToVoting) {
            wrapperRef.current.scrollIntoView();
        }
    }, [])

    return (
        <div ref={wrapperRef} className={styles.sectionWrapper}>
            <PageSection className={styles.pageSection}>
                <SectionName name='Онлайн-голосование' className={styles.sectionName}/>
                <Nomination nomination={nomination}/>
            </PageSection>
        </div>
    );
};

export default Voting;
