import styles from './styles.module.styl';

export default function() {
    // только если десктопа
    if (!window.matchMedia("(min-width: 1200px)").matches) {
        return;
    }

    const text1 = document.getElementById('premium-animation-text1');
    const text2 = document.getElementById('premium-animation-text2');
    const animationContainer = document.getElementById('premium-animation-animation-container');
    const diamond = document.getElementById('premium-animation-diamond');

    const showTopText = () => {
        text1.classList.add(styles.textPart1Appear);
        text1.classList.remove(styles.textPart1Disappear);
    };

    const hideTopText = () => {
        text1.classList.add(styles.textPart1Disappear);
        text1.classList.remove(styles.textPart1Appear);
    };

    const showBottomText = () => {
        text2.classList.add(styles.textPart2Appear);
        text2.classList.remove(styles.textPart2Disappear);
    };

    const hideBottomText = () => {
        text2.classList.add(styles.textPart2Disappear);
        text2.classList.remove(styles.textPart2Appear);
    };

    const TOP = 'top';
    const BOTTOM = 'bottom';
    const wrapper = animationContainer;
    const wrapperOffsetTop = wrapper.offsetTop;
    const wrapperHeight = wrapper.offsetHeight;

    let prevViewportTop = 0;
    let ticking = false;

    const getAnimationBreakpoint = (percent) => {
        return wrapperOffsetTop + wrapperHeight * percent;
    };

    let progress = 0;
    const minProgress = 0;
    const maxProgress = 1;
    const startBreakpoint = getAnimationBreakpoint(1) - window.innerHeight;
    const finishBreakpoint = getAnimationBreakpoint(1);
    const animationHeight = finishBreakpoint - startBreakpoint;
    const diamondRotation = 95;
    const diamondTransition = 400;

    let firstTextShown = true;
    let secondTextShown = false;

    const getProgress = (viewportBottom) => {
        const alreadyPassed = viewportBottom - startBreakpoint;
        const progress = alreadyPassed / animationHeight;
        return progress;
    };

    const changeDiamondPosition = (progress) => {
        diamond.style = `transform: translateX(${diamondTransition * progress}px) rotate(${diamondRotation * progress}deg)`;
    };

    const tryRequestAnimationFrame = (callback) => {
        if( !ticking) {
            window.requestAnimationFrame(function() {
                callback();
                ticking = false;
            });
            ticking = true;
        }
    };

    const handleScroll = () => {
        const viewportTop = window.scrollY;
        const viewportBottom = viewportTop + window.innerHeight;

        const direction = viewportTop > prevViewportTop ? BOTTOM : TOP;

        // поворот рубина вправо
        if (direction === BOTTOM && progress < maxProgress && viewportBottom > startBreakpoint) {
            tryRequestAnimationFrame(function() {
                progress = getProgress(viewportBottom);
                if (progress > 0.4 && !secondTextShown) {
                    hideTopText();
                    showBottomText();
                    secondTextShown = true;
                    firstTextShown = false;
                }
                if (progress > 1) {
                    progress = 1;
                }
                changeDiamondPosition(progress);
            });
        }

        // поворот рубина влево
        if (direction === TOP && progress > minProgress && viewportBottom < finishBreakpoint) {
            tryRequestAnimationFrame(function() {
                progress = getProgress(viewportBottom);
                if (progress < 0.6 && !firstTextShown) {
                    hideBottomText();
                    showTopText();
                    firstTextShown = true;
                    secondTextShown = false;
                }
                if (progress < 0) {
                    progress = 0;
                }
                changeDiamondPosition(progress);
            });
        }

        prevViewportTop = viewportTop;
    };

    window.addEventListener('scroll', handleScroll);
};
