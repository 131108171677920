import React, { useEffect, useState } from "react";
import styles from './styles.module.styl';

import PageSection from "../../components/page-section";

import { BANKIRU_URL } from '../../../constants/index'; 

const News = () => {
    const [news, setNews] = useState(null);

    useEffect(() => {
        async function fetchNews() {
            const res = await fetch(`${BANKIRU_URL}/news/api/bank_of_year/`);
            if (!res.ok || res.status !== 200) {
                console.log('fetch news error');
                return;
            }
            const news = await res.json();
            if (news.error) {
                console.log('fetch news error');
                return;
            }
            setNews(news.items);
        }

        fetchNews();
    }, []);

    const isImageExist = (images) => {
        if (
            images
            && images.picture
            && images.picture.original
            && images.picture.original.file
        ) {
            return true;
        }
        return false;
    };

    const refactorDate = (fullDate) => {
        const date = fullDate.split(' ')[0];
        return date.split('-').reverse().join('.');
    };

    return (
        <div className={styles.wrapper}>
            <PageSection className={styles.newsSection}>
                <h2 className={styles.sectionName}>Новости</h2>

                <div className={styles.newsWidget}>
                    {news ? (
                        <div className={styles.news}>
                            {news.map((newsItem, index) => {
                                return (
                                    <div key={index} className={styles.newsItem}>
                                        {isImageExist(newsItem.images) ? 
                                            <img
                                                src={newsItem.images.picture.original.file}
                                                alt="news item"
                                                className={styles.newsItemImage}
                                            />
                                            :
                                            <div
                                                style={{backgroundColor: '#F4F5F8'}}
                                                className={styles.newsItemImage}
                                            />
                                        }
                                        <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={`${BANKIRU_URL}/news/lenta/?id=${newsItem.id}`}
                                            className={styles.newsItemTitle}
                                        >
                                            {newsItem.title}
                                        </a>
                                        <div className={styles.newsItemDate}>{refactorDate(newsItem.date)}</div>
                                    </div> 
                                );
                            })}
                        </div>
                    ) : (
                        <div className={styles.newsPlaceholder}/>
                    )}

                    <a
                        target='_blank'
                        rel="noreferrer"
                        href={`${BANKIRU_URL}/news/lenta/projects/`}
                        className={styles.allNews}
                    >
                        Все новости
                    </a>
                </div>
            </PageSection>
        </div>
    );
};

export default News;
