import React, { useRef, useState } from "react";
import styles from './styles.module.styl';

import PageSection from "../../components/page-section";
import SectionName from '../../components/section-name';
import Nomination from "./nomination";
import bestCompanies from './data/bestCompanies';
import customerСhoice from "./data/customerСhoice";
import bestProducts from "./data/bestProducts";
import industryLeaders from "./data/industryLeaders";
import specialNominations from "./data/specialNominations";

const Nominations = () => {
    const [showNominations, setShowNominations] = useState(false);
    const additionalNominations = useRef(null);

    const handleShowNominationsClick = () => {
        setShowNominations(true);
    };

    const handleHideNominationsClick = () => {
        setShowNominations(false);
        const additionalNominationsHeight = additionalNominations.current.offsetHeight;
        window.scrollBy(0, -additionalNominationsHeight);
    };

    const createNominations = (nominations) => {
        return nominations.map(nomination => {
            return (
                <Nomination key={nomination.id} nomination={nomination}/>
            );
        })
    };

    const createButtonAria = (text, callback) => {
        return (
            <div className={styles.allNominations}>
                <button
                    type='button'
                    onClick={callback}
                    className={styles.allNominationsButton}
                >
                    {text}
                </button>
                <div className={styles.allNominationsBg}/>
            </div>
        );
    };

    return (
        <div className={styles.sectionWrapper}>
            <PageSection>
                <SectionName
                    name='Номинации'
                    className={styles.sectionName}
                />
                <h3 className={styles.category}>
                    Лучшие финансовые компании года
                </h3>
                {createNominations(bestCompanies)}

                <h3 className={styles.category}>
                    «НАРОДНЫЙ РЕЙТИНГ» БАНКИ.РУ —<br/>
                    ЧЕСТНЫЙ ВЫБОР ПОЛЬЗОВАТЕЛЕЙ
                </h3>
                {createNominations(customerСhoice)}

                {!showNominations && createButtonAria('Все номинации', handleShowNominationsClick)}

                {showNominations &&
                    <div
                        ref={additionalNominations}
                        className={styles.additionalNominations}
                    >
                        <h3 className={styles.category}>
                            Лучшие финансовые продукты
                        </h3>
                        {createNominations(bestProducts)}

                        <h3 className={styles.category}>
                            Лидеры отрасли
                        </h3>
                        {createNominations(industryLeaders)}

                        <h3 className={styles.category}>
                            Спецноминации
                        </h3>
                        {createNominations(specialNominations)}
                    </div>
                }

                {showNominations && createButtonAria('Скрыть номинации', handleHideNominationsClick)}
            </PageSection>
        </div>
        
    );
};

export default Nominations;
