
const industryLeaders = [
    {
        id: '21',
        title: 'Бизнес-лидер года',
        description: 'Победитель определяется экспертным советом премии. Бизнес-лидером года может стать топ-менеджер, проработавший в своей текущей должности не менее 12 месяцев, под руководством которого в прошедшем году банк / страховая компания / инвестиционная компания / микрофинансовая организация достиг (-ла) существенных результатов. Учитываются как финансовые показатели организации, так и события и решения, позитивно повлиявшие на ее развитие',
    },
];

export default industryLeaders;
