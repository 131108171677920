import React, { useEffect, useRef } from 'react';
import styles from './styles.module.styl';

import PageSection from '../../components/page-section';

import videoDesk from '../../static/videos/video-desk.mp4';
import videoTab from '../../static/videos/video-tab.mp4';
import videoMob from '../../static/videos/video-mob.mp4';
import posterDesk from '../../static/images/splash-screen/poster-desk.jpg';
import posterTab from '../../static/images/splash-screen/poster-tab.jpg';
import posterMob from '../../static/images/splash-screen/poster-mob.jpg';

import initAnimation from './animation';
import { getDeviceType, DESKTOP, TABLET } from '../../utils';


const SplashScreen = () => {
	const videoRef = useRef(null);

	useEffect(() => {
		const setVideoProps = () => {
            const device = getDeviceType();
			const video = videoRef.current;
			if (device === DESKTOP) {
				video.src = videoDesk;
				video.poster = posterDesk;
			} else if (device === TABLET) {
				video.src = videoTab;
				video.poster = posterTab;
			} else {
				video.src = videoMob;
				video.poster = posterMob;
			}
        }

		setVideoProps();
		initAnimation();
	}, []);

	return (
		<div
			id='splash-screen-wrapper'
			className={styles.wrapper}
		>
			<PageSection className={styles.splashScreen}>
				<h3 className={styles.title}>
					XV&nbsp;Церемония вручения&nbsp;премии
				</h3>

				<video
					ref={videoRef}
					autoPlay
					loop
					muted
					className={styles.video}
				/>
			</PageSection>
		</div>
	);
};

export default SplashScreen;
