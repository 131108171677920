import React from "react";
import styles from './styles.module.styl';

import PageSection from "../../components/page-section";
import SectionName from '../../components/section-name';
import { getBankOfYearUrl, MAX_YEAR } from "../../../constants";

const years = [];

for (let i = MAX_YEAR; i >= 2007; i--) {
    years.push(i);
}

const CURRENT_YEAR = 2021;

const PreviousCeremonies = () => {
    return (
        <PageSection className={styles.previousCeremonies}>
            <SectionName
                name='Все церемонии'
                className={styles.sectionName}
            />
            
            <div className={styles.ceremoniesContainer}>
                <div className={styles.ceremonies}>
                    {years.map((year, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className={`${styles.ceremony} ${year === CURRENT_YEAR ? styles.currentCeremony : ''}`}>
                                    <a href={getBankOfYearUrl(year)} className={styles.circle}></a>
                                    <div className={styles.year}>{year}</div>
                                </div>
                                {index !== years.length - 1 && <div className={styles.line}/>}
                            </React.Fragment>
                        );
                    })}
                    
                </div>
            </div>
        </PageSection>
    );
};

export default PreviousCeremonies;
