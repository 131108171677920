import React from "react";
import styles from './styles.module.styl';

const Line = ({ className='' }) => {
    return (
        <div className={`${styles.line} ${className}`}/>
    );
};

export default Line;
