import tinkoff from '../../../../assets/images/tinkoff.svg'
import alfaBank from '../../../../assets/images/alfa-bank.svg'
import moneyMan from '../../../../assets/images/moneyMan.svg'
import alfaInsurence from '../../../../assets/images/alfa-insurence.svg'

const bestCompanies = [
    {
        id: '01',
        title: 'Банк года',
        description: 'При выборе победителя мы оцениваем ключевые финансовые показатели банка, рост его бизнеса и занимаемую им долю рынка. Учитываем главные достижения компании в 2021 году — успешные результаты запущенного в 2021 году крупного проекта или достижения в отраслевых рейтингах, рост бизнеса за счет M&A или его масштабирование в смежных секторах',
        winnerLogo: alfaBank,
    },
    {
        id: '02',
        title: 'МФК года',
        description: 'При выборе победителя мы оцениваем динамику показателей основного бизнеса компании — учитываем рост портфеля микрозаймов, объем выдач, рыночную долю бизнеса, финансовую надежность МФК. Кроме того, мы принимаем во внимание отзывы заемщиков и клиентов компании',
        winnerLogo: moneyMan,
    },
    {
        id: '03',
        title: 'Инвестиционная компания года',
        description: 'При выборе победителя мы оцениваем ключевые финансовые показатели компании, рост ее бизнеса и занимаемую ею долю рынка. Обращаем внимание на основные достижения компании в 2021 году — успешные результаты запущенного в 2021 году крупного проекта или достижения в отраслевых рейтингах, рост бизнеса за счет M&A или его масштабирование в смежных секторах',
        winnerLogo: tinkoff,
    },
    {
        id: '04',
        title: 'Страховая компания года',
        description: 'При выборе победителя мы оцениваем ключевые финансовые показатели компании, уровень цифровизации бизнеса (количество цифровых продуктов, реализованных на сайте страховой компании и через партнеров). Принимаем во внимание основные достижения компании в 2021 году — запуск инновационных продуктов и страховых сервисов, крупные отраслевые проекты',
        winnerLogo: alfaInsurence,
    }
];

export default bestCompanies;
