import React from 'react';
import styles from './styles.module.styl';
import './global-styles.styl';
import { Helmet } from 'react-helmet';

import Header from './components/header';
import SplashScreen from './sections/splash-screen';
import AboutPremium from './sections/about-premium';
import PremiumAnimation from './sections/premiumAnimation';
import Nomitations from './sections/nominations';
import Voting from './sections/voting';
import BlitzInterview from './sections/blitz-interview';
import PreviousCeremonies from './sections/previous-ceremonies';
import ObliqueSpacer from './sections/oblique-spacer';
import News from './sections/news';
import Footer from './components/footer';

import bgLightDesc from './static/images/backgrounds/bg-light-desc.png';
import bgLightDescWebp from './static/images/backgrounds/bg-light-desc.webp';

import bgLightMob from './static/images/backgrounds/bg-light-mob.png';
import bgLightMobWebp from './static/images/backgrounds/bg-light-mob.webp';

import { getBankOfYearUrl } from '../constants';
const YEAR = 21;

const BankOfYear2021 = () => {
	return (
		<div className={styles.page}>
			<Helmet>
				<link
					href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;900;&display=swap"
					rel="stylesheet"
				/>
				<link
					href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap"
					rel="stylesheet"
				/>
				<title>{`Церемония «Банк года '${YEAR}» | Банки.ру»`}</title>
				<meta
					name="description"
					content="Главная финансовая премия BANKI.RU. 16 марта 2021, Москва. Крупнейший независимый финансовый портал."
				/>
				<meta
					name="keywords"
					content={`Банк года 20${YEAR},церемония Банк года,рейтинг банков,список банков,рейтинг банков москвы,сбербанк,банки москвы,центральный банк,рейтинг банков,банки`}
				/>
				<meta property="og:title" content={`Церемония «Банк года '${YEAR}»`} />
				<meta property="twitter:title" content={`Церемония «Банк года '${YEAR}»`} />
				<meta
					property="og:description"
					content="Главная финансовая премия от крупнейшего независимого финансового портала Банки.ру. 16 марта 2021"
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:image"
					content="https://static2.banki.ru/upload/ads/1200630.png"
				/>
				<meta property="og:url" content={getBankOfYearUrl(`20${YEAR}`)} />
			</Helmet>

			<div className={styles.bgDark}/>
			<picture>
				<source media='screen and (max-width: 568px)' srcSet={bgLightMobWebp} type='image/webp' />
				<source media='screen and (max-width: 568px)' srcSet={bgLightMob}  type='image/png' />
				<source srcSet={bgLightDescWebp} type='image/webp' />
				<img
					src={bgLightDesc}
					alt="bg-light"
					className={styles.bgLight}
				/>
			</picture>

			<Header />
			<SplashScreen />
			<AboutPremium />
			<PremiumAnimation />
			<Voting />
			<Nomitations />
			<BlitzInterview />
			<PreviousCeremonies />

			<ObliqueSpacer />
			<div className={styles.mainSections}>
				<News />
				<Footer />
			</div>
		</div>
	);
};

export default BankOfYear2021;
