import React from "react";
import styles from './styles.module.styl';

const PageSection = ({ children, className='' }) => {
    return(
        <section className={`${styles.pageSection} ${className}`}>
            {children}
        </section>
    );
};

export default PageSection;
