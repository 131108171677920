import React from "react";
import styles from './styles.module.styl';

import LayoutWrapper from '../layout-wrapper';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <LayoutWrapper className={styles.bottomBlock}>
                © 2005—2021 ИА&nbsp;«Банки.ру»
            </LayoutWrapper>
        </footer>
    );
};

export default Footer;
